export default {
  translation: {
    common: {
      delete: 'Delete',
      deleteModalTitle: 'Are you sure delete this item?',
      ok: 'Yes',
      cancel: 'No',
      total: 'Total',
      rename: 'Rename',
      name: 'Name',
      save: 'Save',
      namePlaceholder: 'Please input name',
      next: 'Next',
      create: 'Create',
      edit: 'Edit',
      upload: 'Upload',
      english: 'English',
      chinese: 'Simplified Chinese',
      traditionalChinese: 'Traditional Chinese',
      language: 'Language',
      languageMessage: 'Please input your language!',
      languagePlaceholder: 'select your language',
      copy: 'Copy',
      copied: 'Copied',
      comingSoon: 'Coming Soon',
      download: 'Download',
      close: 'Close',
      preview: 'Preview',
      move: 'Move',
      warn: 'Warn',
      action: 'Action',
      s: 'S',
      pleaseSelect: 'Please select',
      pleaseInput: 'Please input',
      submit: 'Submit',
    },
    login: {
      login: 'Sign in',
      signUp: 'Sign up',
      loginDescription: 'We’re so excited to see you again!',
      registerDescription: 'Glad to have you on board!',
      emailLabel: 'Email',
      emailPlaceholder: 'Please input email',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Please input password',
      rememberMe: 'Remember me',
      signInTip: 'Don’t have an account?',
      signUpTip: 'Already have an account?',
      nicknameLabel: 'Nickname',
      nicknamePlaceholder: 'Please input nickname',
      register: 'Create an account',
      continue: 'Continue',
      title: 'Start building your smart assistants.',
      description:
        'Sign up for free to explore top RAG technology. Create knowledge bases and AIs to empower your business.',
      review: 'from 500+ reviews',
    },
    header: {
      knowledgeBase: 'Knowledge Base',
      chat: 'Chat',
      register: 'Register',
      signin: 'Sign in',
      home: 'Home',
      setting: '用户设置',
      logout: '登出',
      fileManager: 'File Management',
      flow: 'Agent',
      search: 'Search',
    },
    knowledgeList: {
      welcome: 'Welcome back',
      description: 'Which knowledge base are we going to use today?',
      createKnowledgeBase: 'Create knowledge base',
      name: 'Name',
      namePlaceholder: 'Please input name!',
      doc: 'Docs',
      searchKnowledgePlaceholder: 'Search',
    },
    knowledgeDetails: {
      dataset: 'Dataset',
      testing: 'Retrieval testing',
      files: 'files',
      configuration: 'Configuration',
      name: 'Name',
      namePlaceholder: 'Please input name!',
      doc: 'Docs',
      datasetDescription:
        '😉 Questions and answers can only be answered after the parsing is successful.',
      addFile: 'Add file',
      searchFiles: 'Search your files',
      localFiles: 'Local files',
      emptyFiles: 'Create empty file',
      webCrawl: 'Web Crawl',
      chunkNumber: 'Chunk Number',
      uploadDate: 'Upload Date',
      chunkMethod: 'Chunk Method',
      enabled: 'Enable',
      disabled: 'Disable',
      action: 'Action',
      parsingStatus: 'Parsing Status',
      processBeginAt: 'Process Begin At',
      processDuration: 'Process Duration',
      progressMsg: 'Progress Msg',
      testingDescription:
        'Conduct a retrieval test to check if RAGFlow can recover the intended content for the LLM.',
      similarityThreshold: 'Similarity threshold',
      similarityThresholdTip:
        "RAGFlow employs either a combination of weighted keyword similarity and weighted vector cosine similarity, or a combination of weighted keyword similarity and weighted reranking score during retrieval. This parameter sets the threshold for similarities between the user query and chunks. Any chunk with a similarity score below this threshold will be excluded from the results.",
      vectorSimilarityWeight: 'Keywords similarity weight',
      vectorSimilarityWeightTip:
        "This sets the weight of keyword similarity in the combined similarity score, either used with vector cosine similarity or with reranking score. The total of the two weights must equal 1.0.",
      testText: 'Test text',
      testTextPlaceholder: 'Input your question here!',
      testingLabel: 'Testing',
      similarity: 'Hybrid Similarity',
      termSimilarity: 'Term Similarity',
      vectorSimilarity: 'Vector Similarity',
      hits: 'Hits',
      view: 'View',
      filesSelected: 'Files Selected',
      upload: 'Upload',
      run: 'Run',
      runningStatus0: 'UNSTART',
      runningStatus1: 'Parsing',
      runningStatus2: 'CANCEL',
      runningStatus3: 'SUCCESS',
      runningStatus4: 'FAIL',
      pageRanges: 'Page Ranges',
      pageRangesTip:
        'page ranges: Define the page ranges that need to be parsed. The pages that not included in these ranges will be ignored.',
      fromPlaceholder: 'from',
      fromMessage: 'Missing start page number',
      toPlaceholder: 'to',
      toMessage: 'Missing end page number(excluded)',
      layoutRecognize: 'Layout recognition',
      layoutRecognizeTip:
        'Use visual models for layout analysis to better identify document structure, find where the titles, text blocks, images, and tables are. Without this feature, only the plain text of the PDF can be obtained.',
      taskPageSize: 'Task page size',
      taskPageSizeMessage: 'Please input your task page size!',
      taskPageSizeTip: `If using layout recognize, the PDF file will be split into groups of successive. Layout analysis will be performed parallelly between groups to increase the processing speed. The 'Task page size' determines the size of groups. The larger the page size is, the lower the chance of splitting continuous text between pages into different chunks.`,
      addPage: 'Add page',
      greaterThan: 'The current value must be greater than to!',
      greaterThanPrevious:
        'The current value must be greater than the previous to!',
      selectFiles: 'Select files',
      changeSpecificCategory: 'Change specific category',
      uploadTitle: 'Click or drag file to this area to upload',
      uploadDescription:
        'Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.',
      chunk: 'Chunk',
      bulk: 'Bulk',
      cancel: 'Cancel',
      rerankModel: 'Rerank Model',
      rerankPlaceholder: 'Please select',
      rerankTip: `If left empty, RAGFlow will use a combination of weighted keyword similarity and weighted vector cosine similarity; if a rerank model is selected, a weighted reranking score will replace the weighted vector cosine similarity.`,
      topK: 'Top-K',
      topKTip: `K chunks will be fed into rerank models.`,
      delimiter: `Delimiter`,
      html4excel: 'Excel to HTML',
      html4excelTip: `Excel will be parsed into HTML table or not. If it's FALSE, every row in Excel will be formed as a chunk.`,
      autoKeywords: 'Auto-keyword',
      autoKeywordsTip: `Extract N keywords for each chunk to increase their ranking for queries containing those keywords. You can check or update the added keywords for a chunk from the chunk list. Be aware that extra tokens will be consumed by the LLM specified in 'System model settings'.`,
      autoQuestions: 'Auto-question',
      autoQuestionsTip: `Extract N questions for each chunk to increase their ranking for queries containing those questions. You can check or update the added questions for a chunk from the chunk list. This feature will not disrupt the chunking process if an error occurs, except that it may add an empty result to the original chunk. Be aware that extra tokens will be consumed by the LLM specified in 'System model settings'.`,
    },
    knowledgeConfiguration: {
      titleDescription:
        'Update your knowledge base configurations here, particularly the chunk method.',
      name: 'Knowledge base name',
      photo: 'Knowledge base photo',
      description: 'Description',
      language: 'Language',
      languageMessage: 'Please input your language!',
      languagePlaceholder: 'Please input your language!',
      permissions: 'Permissions',
      embeddingModel: 'Embedding model',
      chunkTokenNumber: 'Chunk token number',
      chunkTokenNumberMessage: 'Chunk token number is required',
      embeddingModelTip:
        'The model that converts chunks into embeddings. It cannot be changed once the knowledge base has chunks. To switch to a different embedding model, You must delete all chunks in the knowledge base.',
      permissionsTip:
        "If set to 'Team', all team members will be able to manage the knowledge base.",
      chunkTokenNumberTip:
        'It sets the token threshold for a chunk. A paragraph with fewer tokens than this threshold will be combined with the following paragraph until the token count exceeds the threshold, at which point a chunk is created.',
      chunkMethod: 'Chunk method',
      chunkMethodTip: 'Tips are on the right.',
      upload: 'Upload',
      english: 'English',
      chinese: 'Chinese',
      embeddingModelPlaceholder: 'Please select a embedding model',
      chunkMethodPlaceholder: 'Please select a chunk method',
      save: 'Save',
      me: 'Only me',
      team: 'Team',
      cancel: 'Cancel',
      methodTitle: 'Chunk method description',
      methodExamples: 'Examples',
      methodExamplesDescription:
        'The following screenshots are provided for clarity.',
      dialogueExamplesTitle: 'Dialogue examples',
      methodEmpty:
        'This will display a visual explanation of the knowledge base categories',
      book: `<p>Supported file formats are <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      For each book in PDF, please set the <i>page ranges</i> to remove unwanted information and reduce analysis time.</p>`,
      laws: `<p>Supported file formats are <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Legal documents typically follow a rigorous writing format. We use text feature to identify split point. 
      </p><p>
      The chunk has a granularity consistent with 'ARTICLE', ensuring all upper level text is included in the chunk.
      </p>`,
      manual: `<p>Only <b>PDF</b> is supported.</p><p>
      We assume that the manual has a hierarchical section structure, using the lowest section titles as basic unit for chunking documents. Therefore, figures and tables in the same section will not be separated, which may result in larger chunk sizes.
      </p>`,
      naive: `<p>Supported file formats are <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML, HTML</b>.</p>
      <p>This method chunks files using a 'naive' method: </p>
      <p>
      <li>Use vision detection model to split the texts into smaller segments.</li>
      <li>Then, combine adjacent segments until the token count exceeds the threshold specified by 'Chunk token number', at which point a chunk is created.</li></p>`,
      paper: `<p>Only <b>PDF</b> file is supported.</p><p>
      Papers will be split by section, such as <i>abstract, 1.1, 1.2</i>. </p><p>
      This approach enables the LLM to summarize the paper more effectively and to provide more comprehensive, understandable responses. 
      However, it also increases the context for AI conversations and adds to the computational cost for the LLM. So during a conversation, consider reducing the value of ‘<b>topN</b>’.</p>`,
      presentation: `<p>Supported file formats are <b>PDF</b>, <b>PPTX</b>.</p><p>
      Every page in the slides is treated as a chunk, with its thumbnail image stored.</p><p>
      <i>This chunk method is automatically applied to all uploaded PPT files, so you do not need to specify it manually.</i></p>`,
      qa: `
      <p>
      This chunk method supports <b>EXCEL</b> and <b>CSV/TXT</b> file formats.
    </p>
    <li>
      If a file is in <b>Excel</b> format, it should contain two columns
      without headers: one for questions and the other for answers, with the
      question column preceding the answer column. Multiple sheets are
      acceptable, provided the columns are properly structured.
    </li>
    <li>
      If a file is in <b>CSV/TXT</b> format, it must be UTF-8 encoded with TAB as the delimiter to separate questions and answers.
    </li>
    <p>
      <i>
        Lines of texts that fail to follow the above rules will be ignored, and
        each Q&A pair will be considered a distinct chunk.
      </i>
    </p>
      `,
      resume: `<p>Supported file formats are <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.
      </p><p>
      Résumés of various forms are parsed and organized into structured data to facilitate candidate search for recruiters.
      </p>
      `,
      table: `<p>Supported file formats are <b>EXCEL</b> and <b>CSV/TXT</b>.</p><p>
      Here're some prerequisites and tips:
      <ul>
    <li>For CSV or TXT file, the delimiter between columns must be <em><b>TAB</b></em>.</li>
    <li>The first row must be column headers.</li>
    <li>Column headers must be meaningful terms to aid your LLM's understanding.
    It is good practice to juxtapose synonyms separated by a slash <i>'/'</i> and to enumerate values using brackets, for example: <i>'Gender/Sex (male, female)'</i>.<p>
    Here are some examples of headers:<ol>
        <li>supplier/vendor<b>'TAB'</b>Color (Yellow, Blue, Brown)<b>'TAB'</b>Sex/Gender (male, female)<b>'TAB'</b>size (M, L, XL, XXL)</li>
        </ol>
        </p>
    </li>
    <li>Every row in table will be treated as a chunk.</li>
    </ul>`,
      picture: `
    <p>Image files are supported, with video support coming soon.</p><p>
    This method employs an OCR model to extract texts from images.
    </p><p>
    If the text extracted by the OCR model is deemed insufficient, a specified visual LLM will be used to provide a description of the image.
    </p>`,
      one: `
    <p>Supported file formats are <b>DOCX, EXCEL, PDF, TXT</b>.
    </p><p>
    This method treats each document in its entirety as a chunk.
    </p><p>
    Applicable when you require the LLM to summarize the entire document, provided it can handle that amount of context length.
    </p>`,
      knowledgeGraph: `<p>Supported file formats are <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML</b>

<p>This approach chunks files using the 'naive'/'General' method. It splits a document into segements and then combines adjacent segments until the token count exceeds the threshold specified by 'Chunk token number', at which point a chunk is created.</p>
<p>The chunks are then fed to the LLM to extract entities and relationships for a knowledge graph and a mind map.</p>
<p>Ensure that you set the <b>Entity types</b>.</p>`,
      useRaptor: 'Use RAPTOR to enhance retrieval',
      useRaptorTip:
        'Recursive Abstractive Processing for Tree-Organized Retrieval, see https://huggingface.co/papers/2401.18059 for more information',
      prompt: 'Prompt',
      promptTip: 'LLM prompt used for summarization.',
      promptMessage: 'Prompt is required',
      promptText: `Please summarize the following paragraphs. Be careful with the numbers, do not make things up. Paragraphs as following:
      {cluster_content}
The above is the content you need to summarize.`,
      maxToken: 'Max token',
      maxTokenTip: 'Maximum token number for summarization.',
      maxTokenMessage: 'Max token is required',
      threshold: 'Threshold',
      thresholdTip: 'The bigger the threshold is the less cluster will be.',
      thresholdMessage: 'Threshold is required',
      maxCluster: 'Max cluster',
      maxClusterTip: 'Maximum cluster number.',
      maxClusterMessage: 'Max cluster is required',
      randomSeed: 'Random seed',
      randomSeedMessage: 'Random seed is required',
      entityTypes: 'Entity types',
    },
    chunk: {
      chunk: 'Chunk',
      bulk: 'Bulk',
      selectAll: 'Select All',
      enabledSelected: 'Enable Selected',
      disabledSelected: 'Disable Selected',
      deleteSelected: 'Delete Selected',
      search: 'Search',
      all: 'All',
      enabled: 'Enabled',
      disabled: 'Disabled',
      keyword: 'Keyword',
      function: 'Function',
      chunkMessage: 'Please input value!',
      full: 'Full text',
      ellipse: 'Ellipse',
      graph: 'Knowledge graph',
      mind: 'Mind map',
    },
    chat: {
      newConversation: 'New conversation',
      createAssistant: 'Create an Assistant',
      assistantSetting: 'Assistant Setting',
      promptEngine: 'Prompt Engine',
      modelSetting: 'Model Setting',
      chat: 'Chat',
      newChat: 'New chat',
      send: 'Send',
      sendPlaceholder: 'Message the Assistant...',
      chatConfiguration: 'Chat Configuration',
      chatConfigurationDescription:
        ' Here, dress up a dedicated assistant for your special knowledge bases! 💕',
      assistantName: 'Assistant name',
      assistantNameMessage: 'Assistant name is required',
      namePlaceholder: 'e.g. Resume Jarvis',
      assistantAvatar: 'Assistant avatar',
      language: 'Language',
      emptyResponse: 'Empty response',
      emptyResponseTip: `If nothing is retrieved with user's question in the knowledgebase, it will use this as an answer. If you want LLM comes up with its own opinion when nothing is retrieved, leave this blank.`,
      setAnOpener: 'Set an opener',
      setAnOpenerInitial: `Hi! I'm your assistant, what can I do for you?`,
      setAnOpenerTip: 'How do you want to welcome your clients?',
      knowledgeBases: 'Knowledgebases',
      knowledgeBasesMessage: 'Please select',
      knowledgeBasesTip: 'Select knowledgebases associated.',
      system: 'System',
      systemInitialValue: `You are an intelligent assistant. Please summarize the content of the knowledge base to answer the question. Please list the data in the knowledge base and answer in detail. When all knowledge base content is irrelevant to the question, your answer must include the sentence "The answer you are looking for is not found in the knowledge base!" Answers need to consider chat history.
      Here is the knowledge base:
      {knowledge}
      The above is the knowledge base.`,
      systemMessage: 'Please input!',
      systemTip:
        'Instructions you need LLM to follow when LLM answers questions, like charactor design, answer length and answer language etc.',
      topN: 'Top N',
      topNTip: `Not all the chunks whose similarity score is above the 'simialrity threashold' will be feed to LLMs. LLM can only see these 'Top N' chunks.`,
      variable: 'Variable',
      variableTip: `If you use dialog APIs, the varialbes might help you chat with your clients with different strategies. 
      The variables are used to fill-in the 'System' part in prompt in order to give LLM a hint.
      The 'knowledge' is a very special variable which will be filled-in with the retrieved chunks.
      All the variables in 'System' should be curly bracketed.`,
      add: 'Add',
      key: 'Key',
      optional: 'Optional',
      operation: 'Operation',
      model: 'Model',
      modelTip: 'Large language chat model',
      modelMessage: 'Please select!',
      freedom: 'Freedom',
      improvise: 'Improvise',
      precise: 'Precise',
      balance: 'Balance',
      freedomTip: `'Precise' means the LLM will be conservative and answer your question cautiously. 'Improvise' means the you want LLM talk much and freely. 'Balance' is between cautiously and freely.`,
      temperature: 'Temperature',
      temperatureMessage: 'Temperature is required',
      temperatureTip:
        'This parameter controls the randomness of predictions by the model. A lower temperature makes the model more confident in its responses, while a higher temperature makes it more creative and diverse.',
      topP: 'Top P',
      topPMessage: 'Top P is required',
      topPTip:
        'Also known as “nucleus sampling,” this parameter sets a threshold to select a smaller set of words to sample from. It focuses on the most likely words, cutting off the less probable ones.',
      presencePenalty: 'Presence Penalty',
      presencePenaltyMessage: 'Presence Penalty is required',
      presencePenaltyTip:
        'This discourages the model from repeating the same information by penalizing words that have already appeared in the conversation.',
      frequencyPenalty: 'Frequency Penalty',
      frequencyPenaltyMessage: 'Frequency Penalty is required',
      frequencyPenaltyTip:
        'Similar to the presence penalty, this reduces the model’s tendency to repeat the same words frequently.',
      maxTokens: 'Max Tokens',
      maxTokensMessage: 'Max Tokens is required',
      maxTokensTip:
        'This sets the maximum length of the model’s output, measured in the number of tokens (words or pieces of words).',
      quote: 'Show Quote',
      quoteTip: 'Should the source of the original text be displayed?',
      selfRag: 'Self-RAG',
      selfRagTip: 'Please refer to: https://huggingface.co/papers/2310.11511',
      overview: 'Chat ID',
      pv: 'Number of messages',
      uv: 'Active user number',
      speed: 'Token output speed',
      tokens: 'Consume the token number',
      round: 'Session Interaction Number',
      thumbUp: 'customer satisfaction',
      preview: 'Preview',
      embedded: 'Embedded',
      serviceApiEndpoint: 'Service API Endpoint',
      apiKey: 'API KEY',
      apiReference: 'API Documents',
      dateRange: 'Date Range:',
      backendServiceApi: 'API Server',
      createNewKey: 'Create new key',
      created: 'Created',
      action: 'Action',
      embedModalTitle: 'Embed into website',
      comingSoon: 'Coming Soon',
      fullScreenTitle: 'Full Embed',
      fullScreenDescription:
        'Embed the following iframe into your website at the desired location',
      partialTitle: 'Partial Embed',
      extensionTitle: 'Chrome Extension',
      tokenError: 'Please create API Token first!',
      searching: 'searching...',
      parsing: 'Parsing',
      uploading: 'Uploading',
      uploadFailed: 'Upload failed',
      regenerate: 'Regenerate',
      read: 'Read content',
      tts: 'Text to speech',
      ttsTip:
        'To play the voice using voice conversion, please select TTS (speech conversion model) in the settings first.',
      relatedQuestion: 'Related question',
      answerTitle: 'R',
      multiTurn: 'Multi-turn optimization',
      multiTurnTip:
        'In multi-round conversations, the query to the knowledge base is optimized. The large model will be called to consume additional tokens.',
      howUseId: 'How to use chat ID?',
    },
    setting: {
      profile: 'Profile',
      profileDescription: 'Update your photo and personal details here.',
      password: 'Password',
      passwordDescription:
        'Please enter your current password to change your password.',
      model: 'Model Providers',
      modelDescription: 'Set the model parameter and API KEY here.',
      team: 'Team',
      system: 'System',
      logout: 'Log out',
      api: 'API',
      username: 'Username',
      usernameMessage: 'Please input your username!',
      photo: 'Your photo',
      photoDescription: 'This will be displayed on your profile.',
      colorSchema: 'Color schema',
      colorSchemaMessage: 'Please select your color schema!',
      colorSchemaPlaceholder: 'select your color schema',
      bright: 'Bright',
      dark: 'Dark',
      timezone: 'Timezone',
      timezoneMessage: 'Please input your timezone!',
      timezonePlaceholder: 'select your timezone',
      email: 'Email address',
      emailDescription: 'Once registered, E-mail cannot be changed.',
      currentPassword: 'Current password',
      currentPasswordMessage: 'Please input your password!',
      newPassword: 'New password',
      newPasswordMessage: 'Please input your password!',
      newPasswordDescription:
        'Your new password must be more than 8 characters.',
      confirmPassword: 'Confirm new password',
      confirmPasswordMessage: 'Please confirm your password!',
      confirmPasswordNonMatchMessage:
        'The new password that you entered do not match!',
      cancel: 'Cancel',
      addedModels: 'Added models',
      modelsToBeAdded: 'Models to be added',
      addTheModel: 'Add the model',
      apiKey: 'API-Key',
      apiKeyMessage:
        'Please enter the API key (for locally deployed model,ignore this).',
      apiKeyTip:
        'The API key can be obtained by registering the corresponding LLM supplier.',
      showMoreModels: 'Show more models',
      baseUrl: 'Base-Url',
      baseUrlTip:
        'If your API key is from OpenAI, just ignore it. Any other intermediate providers will give this base url with the API key.',
      modify: 'Modify',
      systemModelSettings: 'System Model Settings',
      chatModel: 'Chat model',
      chatModelTip:
        'The default chat LLM all the newly created knowledgebase will use.',
      embeddingModel: 'Embedding model',
      embeddingModelTip:
        'The default embedding model all the newly created knowledgebase will use.',
      img2txtModel: 'Img2txt model',
      img2txtModelTip:
        'The default multi-module model all the newly created knowledgebase will use. It can describe a picture or video.',
      sequence2txtModel: 'Sequence2txt model',
      sequence2txtModelTip:
        'The default ASR model all the newly created knowledgebase will use. Use this model to translate voices to corresponding text.',
      rerankModel: 'Rerank Model',
      rerankModelTip: `The default rerank model is used to rerank chunks retrieved by users' questions.`,
      ttsModel: 'TTS Model',
      ttsModelTip:
        'The default TTS model will be used to generate speech during conversations upon request.',
      workspace: 'Workspace',
      upgrade: 'Upgrade',
      addLlmTitle: 'Add LLM',
      modelName: 'Model name',
      modelID: 'Model ID',
      modelUid: 'Model UID',
      modelNameMessage: 'Please input your model name!',
      modelType: 'Model type',
      modelTypeMessage: 'Please input your model type!',
      addLlmBaseUrl: 'Base url',
      baseUrlNameMessage: 'Please input your base url!',
      vision: 'Does it support Vision?',
      ollamaLink: 'How to integrate {{name}}',
      FishAudioLink: 'How to use FishAudio',
      TencentCloudLink: 'How to use TencentCloud ASR',
      volcModelNameMessage: 'Please input your model name!',
      addEndpointID: 'EndpointID of the model',
      endpointIDMessage: 'Please input your EndpointID of the model',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: 'Please input your ARK_API_KEY',
      bedrockModelNameMessage: 'Please input your model name!',
      addBedrockEngineAK: 'ACCESS KEY',
      bedrockAKMessage: 'Please input your ACCESS KEY',
      addBedrockSK: 'SECRET KEY',
      bedrockSKMessage: 'Please input your SECRET KEY',
      bedrockRegion: 'AWS Region',
      bedrockRegionMessage: 'Please select!',
      'us-east-1': 'US East (N. Virginia)',
      'us-west-2': 'US West (Oregon)',
      'ap-southeast-1': 'Asia Pacific (Singapore)',
      'ap-northeast-1': 'Asia Pacific (Tokyo)',
      'eu-central-1': 'Europe (Frankfurt)',
      'us-gov-west-1': 'AWS GovCloud (US-West)',
      'ap-southeast-2': 'Asia Pacific (Sydney)',
      addHunyuanSID: 'Hunyuan Secret ID',
      HunyuanSIDMessage: 'Please input your Secret ID',
      addHunyuanSK: 'Hunyuan Secret Key',
      HunyuanSKMessage: 'Please input your Secret Key',
      addTencentCloudSID: 'TencentCloud Secret ID',
      TencentCloudSIDMessage: 'Please input your Secret ID',
      addTencentCloudSK: 'TencentCloud Secret Key',
      TencentCloudSKMessage: 'Please input your Secret Key',
      SparkModelNameMessage: 'Please select Spark model',
      addSparkAPIPassword: 'Spark APIPassword',
      SparkAPIPasswordMessage: 'please input your APIPassword',
      addSparkAPPID: 'Spark APPID',
      SparkAPPIDMessage: 'please input your APPID',
      addSparkAPISecret: 'Spark APISecret',
      SparkAPISecretMessage: 'please input your APISecret',
      addSparkAPIKey: 'Spark APIKey',
      SparkAPIKeyMessage: 'please input your APIKey',
      yiyanModelNameMessage: 'Please input model name',
      addyiyanAK: 'yiyan API KEY',
      yiyanAKMessage: 'Please input your API KEY',
      addyiyanSK: 'yiyan Secret KEY',
      yiyanSKMessage: 'Please input your Secret KEY',
      FishAudioModelNameMessage:
        'Please give your speech synthesis model a name',
      addFishAudioAK: 'Fish Audio API KEY',
      addFishAudioAKMessage: 'Please input your API KEY',
      addFishAudioRefID: 'FishAudio Refrence ID',
      addFishAudioRefIDMessage:
        'Please input the Reference ID (leave blank to use the default model).',
      GoogleModelIDMessage: 'Please input your model ID!',
      addGoogleProjectID: 'Project ID',
      GoogleProjectIDMessage: 'Please input your Project ID',
      addGoogleServiceAccountKey:
        'Service Account Key(Leave blank if you use Application Default Credentials)',
      GoogleServiceAccountKeyMessage:
        'Please input Google Cloud Service Account Key in base64 format',
      addGoogleRegion: 'Google Cloud Region',
      GoogleRegionMessage: 'Please input Google Cloud Region',
      modelProvidersWarn: `Please add both embedding model and LLM in <b>Settings > Model providers</b>  firstly. Then, set them in 'System model settings'.`,
      apiVersion: 'API-Version',
      apiVersionMessage: 'Please input API version',
      add: 'Add',
      updateDate: 'Update Date',
      role: 'Role',
      invite: 'Invite',
      agree: 'Agree',
      refuse: 'Refuse',
      teamMembers: 'Team Members',
      joinedTeams: 'Joined Teams',
    },
    message: {
      registered: 'Registered!',
      logout: 'logout',
      logged: 'logged!',
      pleaseSelectChunk: 'Please select chunk!',
      modified: 'Modified',
      created: 'Created',
      deleted: 'Deleted',
      renamed: 'Renamed',
      operated: 'Operated',
      updated: 'Updated',
      uploaded: 'Uploaded',
      200: 'The server successfully returns the requested data.',
      201: 'Create or modify data successfully.',
      202: 'A request has been queued in the background (asynchronous task).',
      204: 'Data deleted successfully.',
      400: 'There was an error in the request issued, and the server did not create or modify data.',
      401: 'The user does not have permissions (wrong token, username, password).',
      403: 'The user is authorized, but access is prohibited.',
      404: 'The request was made for a record that does not exist, and the server did not perform the operation.',
      406: 'The requested format is not available.',
      410: 'The requested resource has been permanently deleted and will not be available again.',
      413: 'The total size of the files uploaded at once is too large.',
      422: 'When creating an object, a validation error occurred.',
      500: 'A server error occurred, please check the server.',
      502: 'Gateway error.',
      503: 'The service is unavailable and the server is temporarily overloaded or undergoing maintenance.',
      504: 'Gateway timeout.',
      requestError: 'Request error',
      networkAnomalyDescription:
        'There is an abnormality in your network and you cannot connect to the server.',
      networkAnomaly: 'network anomaly',
      hint: 'hint',
    },
    fileManager: {
      name: 'Name',
      uploadDate: 'Upload Date',
      knowledgeBase: 'Knowledge Base',
      size: 'Size',
      action: 'Action',
      addToKnowledge: 'Link to Knowledge Base',
      pleaseSelect: 'Please select',
      newFolder: 'New Folder',
      file: 'File',
      uploadFile: 'Upload File',
      directory: 'Directory',
      uploadTitle: 'Click or drag file to this area to upload',
      uploadDescription:
        'Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.',
      local: 'Local uploads',
      s3: 'S3 uploads',
      preview: 'Preview',
      fileError: 'File error',
      uploadLimit:
        'The file size cannot exceed 10M, and the total number of files cannot exceed 128',
      destinationFolder: 'Destination folder',
    },
    flow: {
      cite: 'Cite',
      citeTip: 'citeTip',
      name: 'Name',
      nameMessage: 'Please input name',
      description: 'Description',
      examples: 'Examples',
      to: 'To',
      msg: 'Messages',
      messagePlaceholder: 'message',
      messageMsg: 'Please input message or delete this field.',
      addField: 'Add field',
      addMessage: 'Add message',
      loop: 'Loop',
      loopTip:
        'Loop is the upper limit of the number of loops of the current component, when the number of loops exceeds the value of loop, it means that the component can not complete the current task, please re-optimize agent',
      yes: 'Yes',
      no: 'No',
      key: 'Key',
      componentId: 'Component ID',
      add: 'Add',
      operation: 'operation',
      run: 'Run',
      save: 'Save',
      title: 'ID:',
      beginDescription: 'This is where the flow begins.',
      answerDescription: `A component that serves as the interface between human and bot, receiving user inputs and displaying the agent's responses.`,
      retrievalDescription: `A component that retrieves information from a specified knowledge base and returns 'Empty response' if no information is found. Ensure the correct knowledge base is selected.`,
      generateDescription: `A component that prompts the LLM to generate responses. Ensure the prompt is set correctly.`,
      categorizeDescription: `A component that uses the LLM to classify user inputs into predefined categories. Ensure you specify the name, description, and examples for each category, along with the corresponding next component.`,
      relevantDescription: `A component that uses the LLM to assess whether the upstream output is relevant to the user's latest query. Ensure you specify the next component for each judge result.`,
      rewriteQuestionDescription: `A component that refines a user query if it fails to retrieve relevant information from the knowledge base. It repeats this process until the predefined looping upper limit is reached. Ensure its upstream is 'Relevant' and downstream is 'Retrieval'. `,
      messageDescription:
        "A component that sends out a static message. If multiple messages are supplied, it randomly selects one to send. Ensure its downstream is 'Answer', the interface component.",
      keywordDescription: `A component that retrieves top N search results from user's input. Ensure the TopN value is set properly before use.`,
      switchDescription: `A component that evaluates conditions based on the output of previous components and directs the flow of execution accordingly. It allows for complex branching logic by defining cases and specifying actions for each case or default action if no conditions are met.`,
      wikipediaDescription: `This component is used to get search result from wikipedia.org. Typically, it performs as a supplement to knowledgebases. Top N specifies the number of search results you need to adapt.`,
      promptText: `Please summarize the following paragraphs. Be careful with the numbers, do not make things up. Paragraphs as following:
        {input}
  The above is the content you need to summarize.`,
      createGraph: 'Create agent',
      createFromTemplates: 'Create from templates',
      retrieval: 'Retrieval',
      generate: 'Generate',
      answer: 'Interact',
      categorize: 'Categorize',
      relevant: 'Relevant',
      rewriteQuestion: 'Rewrite',
      rewrite: 'Rewrite',
      begin: 'Begin',
      message: 'Message',
      blank: 'Blank',
      createFromNothing: 'Create your agent from scratch',
      addItem: 'Add Item',
      addSubItem: 'Add Sub Item',
      nameRequiredMsg: 'Name is required',
      nameRepeatedMsg: 'The name cannot be repeated',
      keywordExtract: 'Keyword',
      keywordExtractDescription: `A component that extracts keywords from a user query, with Top N specifing the number of keywords to extract.`,
      baidu: 'Baidu',
      baiduDescription: `This component is used to get search result from www.baidu.com. Typically, it performs as a supplement to knowledgebases. Top N specifies the number of search results you need to adapt.`,
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'A component that retrieves search results from duckduckgo.com, with TopN specifying the number of search results. It supplements existing knowledge bases.',
      channel: 'Channel',
      channelTip: `Perform text search or news search on the component's input`,
      text: 'Text',
      news: 'News',
      messageHistoryWindowSize: 'Message window size',
      messageHistoryWindowSizeTip:
        'The  window size of conversation history that needed to be seen by LLM. The larger the better. But be careful with the maximum content length of LLM.',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      pubMedDescription:
        'This component is used to get search result from https://pubmed.ncbi.nlm.nih.gov/. Typically, it performs as a supplement to knowledgebases. Top N specifies the number of search results you need to adapt. E-mail is a required field.',
      email: 'Email',
      emailTip:
        'This component is used to get search result from https://pubmed.ncbi.nlm.nih.gov/. Typically, it performs as a supplement to knowledgebases. Top N specifies the number of search results you need to adapt. E-mail is a required field.',
      arXiv: 'ArXiv',
      arXivDescription:
        'This component is used to get search result from https://arxiv.org/. Typically, it performs as a supplement to knowledgebases. Top N specifies the number of search results you need to adapt.',
      sortBy: 'Sort by',
      submittedDate: 'Submitted date',
      lastUpdatedDate: 'Last updated date',
      relevance: 'Relevance',
      google: 'Google',
      googleDescription:
        'This component is used to get search result fromhttps://www.google.com/ . Typically, it performs as a supplement to knowledgebases. Top N and SerpApi API key specifies the number of search results you need to adapt.',
      bing: 'Bing',
      bingDescription:
        'This component is used to get search result from https://www.bing.com/. Typically, it performs as a supplement to knowledgebases. Top N and Bing Subscription-Key specifies the number of search results you need to adapt.',
      apiKey: 'API KEY',
      country: 'Country',
      language: 'Language',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'This component is used to get search result from https://scholar.google.com/. Typically, it performs as a supplement to knowledgebases. Top N specifies the number of search results you need to adapt.',
      yearLow: 'Year low',
      yearHigh: 'Year high',
      patents: 'Patents',
      data: 'Data',
      deepL: 'DeepL',
      deepLDescription:
        'This component is used to get translations from https://www.deepl.com/. Typically, it provides a more specialized translation result.',
      authKey: 'Auth key',
      sourceLang: 'Source language',
      targetLang: 'Target language',
      gitHub: 'GitHub',
      githubDescription:
        'This component is used to search the repository from https://github.com/. Top N specifies the number of search results to be adjusted.',
      baiduFanyi: 'BaiduFanyi',
      baiduFanyiDescription:
        'This component is used to get translations from https://fanyi.baidu.com/. Typically, it provides a more specialized translation result',
      appid: 'App id',
      secretKey: 'Secret key',
      domain: 'Domain',
      transType: 'Translation type',
      baiduSecretKeyOptions: {
        translate: 'General translation',
        fieldtranslate: 'Field translation',
      },
      baiduDomainOptions: {
        it: 'Information technology',
        finance: 'Financial and economics',
        machinery: 'Machinery manufacturing',
        senimed: 'Biomedicine',
        novel: 'Online literature',
        academic: 'Academic paper',
        aerospace: 'Aerospace',
        wiki: 'Humanities and social sciences',
        news: 'News and information',
        law: 'Laws and regulations',
        contract: 'Contract',
      },
      baiduSourceLangOptions: {
        auto: 'Auto detect',
        zh: 'Chinese',
        en: 'English',
        yue: 'Cantonese',
        wyw: 'Classical Chinese',
        jp: 'Japanese',
        kor: 'Korean',
        fra: 'French',
        spa: 'Spanish',
        th: 'Thai',
        ara: 'Arabic',
        ru: 'Russian',
        pt: 'Portuguese',
        de: 'German',
        it: 'Italian',
        el: 'Greek',
        nl: 'Dutch',
        pl: 'Polish',
        bul: 'Bulgarian',
        est: 'Estonian',
        dan: 'Danish',
        fin: 'Finnish',
        cs: 'Czech',
        rom: 'Romanian',
        slo: 'Slovenian',
        swe: 'Swedish',
        hu: 'Hungarian',
        cht: 'Traditional Chinese',
        vie: 'Vietnamese',
      },
      qWeather: 'QWeather',
      qWeatherDescription:
        'This component is used to get weather related information from https://www.qweather.com/. You can get weather, indices, air quality.',
      lang: 'Language',
      type: 'Type',
      webApiKey: 'Web API key',
      userType: 'User type',
      timePeriod: 'Time period',
      qWeatherLangOptions: {
        zh: 'Simplified Chinese',
        'zh-hant': 'Traditional Chinese',
        en: 'English',
        de: 'German',
        es: 'Spanish',
        fr: 'French',
        it: 'Italian',
        ja: 'Japanese',
        ko: 'Korean',
        ru: 'Russian',
        hi: 'Hindi',
        th: 'Thai',
        ar: 'Arabic',
        pt: 'Portuguese',
        bn: 'Bengali',
        ms: 'Malay',
        nl: 'Dutch',
        el: 'Greek',
        la: 'Latin',
        sv: 'Swedish',
        id: 'Indonesian',
        pl: 'Polish',
        tr: 'Turkish',
        cs: 'Czech',
        et: 'Estonian',
        vi: 'Vietnamese',
        fil: 'Filipino',
        fi: 'Finnish',
        he: 'Hebrew',
        is: 'Icelandic',
        nb: 'Norwegian',
      },
      qWeatherTypeOptions: {
        weather: 'Weather forecast',
        indices: 'Weather life index',
        airquality: 'Air quality',
      },
      qWeatherUserTypeOptions: {
        free: 'Free subscriber',
        paid: 'Paid subscriber',
      },
      qWeatherTimePeriodOptions: {
        now: 'Now',
        '3d': '3 days',
        '7d': '7 days',
        '10d': '10 days',
        '15d': '12 days',
        '30d': '30 days',
      },
      publish: 'API',
      exeSQL: 'ExeSQL',
      exeSQLDescription:
        'The component queries the results from the corresponding relational database via SQL statements. Supports MySQL, PostgreSQL, MariaDB. ',
      dbType: 'Database Type',
      database: 'Database',
      username: 'Username',
      host: 'Host',
      port: 'Port',
      password: 'Password',
      switch: 'Switch',
      logicalOperator: 'Logical operator',
      switchOperatorOptions: {
        equal: 'equal',
        notEqual: 'notEqual',
        gt: 'Greater than',
        ge: 'Greater equal',
        lt: 'Less than',
        le: 'Less equal',
        contains: 'Contains',
        notContains: 'Not contains',
        startWith: 'Start with',
        endWith: 'End with',
        empty: 'Empty',
        notEmpty: 'Not empty',
      },
      switchLogicOperatorOptions: {
        and: 'And',
        or: 'Or',
      },
      operator: 'Operator',
      value: 'Value',
      useTemplate: 'Use this template',
      wenCai: 'WenCai',
      queryType: 'Query type',
      wenCaiDescription:
        'The component can be used to obtain information on a wide range of financial areas, including but not limited to stocks, funds, etc...',
      wenCaiQueryTypeOptions: {
        stock: 'stock',
        zhishu: 'index',
        fund: 'fund',
        hkstock: 'Hong Kong shares',
        usstock: 'US stock market',
        threeboard: 'New OTC Market',
        conbond: 'Convertible Bond',
        insurance: 'insurance',
        futures: 'futures',
        lccp: 'Financing',
        foreign_exchange: 'Foreign currency',
      },
      akShare: 'AkShare',
      akShareDescription:
        'This component can be used to obtain news information for the corresponding stock from the Eastmoney website.',
      yahooFinance: 'YahooFinance',
      yahooFinanceDescription:
        'The component queries information about the company based on the provided ticker symbol.',
      crawler: 'Web Crawler',
      crawlerDescription:
        'This component can be used to crawl HTML source code from a specified URL.',
      proxy: 'Proxy',
      crawlerResultOptions: {
        html: 'Html',
        markdown: 'Markdown',
        content: 'Content',
      },
      extractType: 'Extract type',
      info: 'Info',
      history: 'History',
      financials: 'Financials',
      balanceSheet: 'Balance sheet',
      cashFlowStatement: 'Cash flow statement',
      jin10: 'Jin10',
      jin10Description:
        'This component can be used to access information in the financial sector from the Jin10 Open Platform, including quick news, calendar, quotes, reference.',
      flashType: 'Flash type',
      filter: 'Filter',
      contain: 'Contain',
      calendarType: 'Calendar type',
      calendarDatashape: 'Calendar datashape',
      symbolsDatatype: 'Symbols datatype',
      symbolsType: 'Symbols type',
      jin10TypeOptions: {
        flash: 'Quick News',
        calendar: 'Calendar',
        symbols: 'quotes',
        news: 'reference',
      },
      jin10FlashTypeOptions: {
        '1': 'Market News',
        '2': ' Futures News',
        '3': 'US-Hong Kong News',
        '4': 'A-Share News',
        '5': 'Commodities & Forex News',
      },
      jin10CalendarTypeOptions: {
        cj: 'Macroeconomic Data Calendar',
        qh: ' Futures Calendar',
        hk: 'Hong Kong Stock Market Calendar',
        us: 'US Stock Market Calendar',
      },
      jin10CalendarDatashapeOptions: {
        data: 'Data',
        event: ' Event',
        holiday: 'Holiday',
      },
      jin10SymbolsTypeOptions: {
        GOODS: 'Commodity Quotes',
        FOREX: ' Forex Quotes',
        FUTURE: 'International Market Quotes',
        CRYPTO: 'Cryptocurrency Quotes',
      },
      jin10SymbolsDatatypeOptions: {
        symbols: 'Commodity List',
        quotes: ' Latest Market Quotes',
      },
      concentrator: 'Concentrator',
      concentratorDescription:
        'A component that receives the output from the upstream component and passes it on as input to the downstream components.',
      tuShare: 'TuShare',
      tuShareDescription:
        'This component can be used to obtain financial news briefs from mainstream financial websites, aiding industry and quantitative research.',
      tuShareSrcOptions: {
        sina: 'Sina',
        wallstreetcn: 'wallstreetcn',
        '10jqka': 'Straight flush',
        eastmoney: 'Eastmoney',
        yuncaijing: 'YUNCAIJING',
        fenghuang: 'FENGHUANG',
        jinrongjie: 'JRJ',
      },
      token: 'Token',
      src: 'Source',
      startDate: 'Start date',
      endDate: 'End date',
      keyword: 'Keyword',
      note: 'Note',
      noteDescription: 'Note',
      notePlaceholder: 'Please enter a note',
      invoke: 'Invoke',
      invokeDescription:
        'This component can invoke remote end point call. Put the output of other components as parameters or set constant parameters to call remote functions.',
      url: 'Url',
      method: 'Method',
      timeout: 'Timeout',
      headers: 'Headers',
      cleanHtml: 'Clean HTML',
      cleanHtmlTip:
        'If the response is HTML formatted and only the primary content wanted, please toggle it on.',
      reference: 'Reference',
      input: 'Input',
      output: 'Output',
      parameter: 'Parameter',
      howUseId: 'How to use agent ID?',
      content: 'Content',
      operationResults: 'Operation Results',
      autosaved: 'Autosaved',
      optional: 'Optional',
      pasteFileLink: 'Paste file link',
      testRun: 'Test Run',
    },
    footer: {
      profile: 'All rights reserved @ React',
    },
    layout: {
      file: 'file',
      knowledge: 'knowledge',
      chat: 'chat',
    },
  },
};
